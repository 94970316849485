import React from "react";

class LandingFaqItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  toggleIsOpen() {
    const { isOpen } = this.state;
    this.setState({isOpen: !isOpen});
  }

  renderDescription() {
    const { item, overnight } = this.props;
    return (
      <div className={`body1 landing-faq-item-description ${overnight ? 'landing-faq-item-description-overnight' : ''}`} dangerouslySetInnerHTML={{__html: item.data["CTQ-1731955319-ea11"]}}/>
    );
  }

  render() {
    const { item, overnight, tile_view } = this.props;
    const { isOpen } = this.state;
    return (
      <div className={`landing-faq-item-wrapper ${overnight ? 'landing-faq-item-wrapper-overnight' : ''} ${tile_view ? 'landing-page-faq-tile' : ''}`}>
        <div className="landing-faq-item-header" onClick={this.toggleIsOpen}>
          <h6>
            <div dangerouslySetInnerHTML={{__html: item.data["CTQ-1731955311-51e2"]}} />
          </h6>
          <div>
            <img src={`/images/landing/ico-chevron-${isOpen ? 'up' : 'down'}.svg`}/>
          </div>
        </div>
        {
          isOpen ?
          this.renderDescription()
          :
          <></>
        }
      </div>
    );
  }
}

export default LandingFaqItem;
