import React from "react";

class LandingPageSchedule extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      }
  }

  getStyle() {
    const { overnight } = this.props;
    let style={}
    if (overnight) {
      style={color: "#ffffff"}
    }
    return style;
  }

  renderScheduleItem(item) {
    return (
      <tr>
        <td className="landing-page-content-table-time body1" style={this.getStyle()} dangerouslySetInnerHTML={{__html: item.data['CTQ-1731954236-dd5b']}}/>
        <td className="landing-page-content-table-info" style={this.getStyle()} dangerouslySetInnerHTML={{__html: item.data['CTQ-1731954248-5d16']}}/>
      </tr>
    );
  }

  getFilteredItems() {
    const { cms_section_schedule, slug_to_keep } = this.props;
    if (slug_to_keep === undefined) return cms_section_schedule["web_pages"]
    return cms_section_schedule["web_pages"].filter(item => item.slug.includes(slug_to_keep))
  }

  render() {
    let filteredItems = this.getFilteredItems();
    let renderedScheduleItems = filteredItems.sort((a,b) => a.data['CTQ-1731954257-c736'] - b.data['CTQ-1731954257-c736']).map((item, index) =>
      <tbody key={`schedule-${index}`}>
        {this.renderScheduleItem(item)}
      </tbody>
    );
    return (
      <table className="landing-page-content-table">
        {renderedScheduleItems}
      </table>
    )
  }
}

export default LandingPageSchedule;
